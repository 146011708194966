/* .bg-header {
  background-image: url('/public/images/bg-1920.jpg');
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
} */

/* .aspect-ratio-box {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
} */

.socmed-icon-size {
  width: 48px;
  height: 48px;
}

.hero-section {
  min-height: 600px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-image {
  max-width: 36%;
}

.logo-title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.header-text {
  text-align: center;
  font-size: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  max-width: 60%;
  font-weight: 700;
}

.content-container {
  align-items: center;
  margin-left: 3rem;
  margin-right: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 36px;
}

.content-text {
  width: 50%;
  font-size: 1.2rem;
  line-height: 1.5;
  text-align: center;
}

.container-image {
  /* width: 60%; */
  display: flex;
  overflow: hidden;
  justify-content: center;
}

.content-image {
  width: 50%;
  max-width: 1200px;
}

.middle-label {
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  font-size: 1.5rem;
  line-height: 2rem;
}
.footer-text {
  text-align: center;
}

.content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-media-image {
  width: 100%;
  height: 100%;
}

.social-media-link {
  width: 64px;
  height: 64px;
}

/* mobile */
@media (max-width: 1199px) {
  .logo-image {
    max-width: 80%;
  }

  .header-text {
    font-size: 1.25rem;
    max-width: 80%;
  }

  .socmed-icon-size {
    width: 32px;
    height: 32px;
  }

  .content-image {
    width: 100%;
  }

  .content-container {
    align-items: center;
    margin-left: 0rem;
    margin-right: 0rem;
    margin-top: 0
  }

  .content-text {
    font-size: 1rem;
    line-height: 1.25;
    text-align: center;
    width: 100%;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
