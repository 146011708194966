body {
  margin-top: 0;
  margin-bottom: 0;

  margin-left: auto;
  margin-right: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #FAF9F6; */
  background-color: #0b0b0b;
  max-width: 1920px;
  /* color: #7c3000; */
  color: #eaddb7;
}

h1,
p {
  margin: 0;
}

.press-start-2p {
  font-family: "Press Start 2P", system-ui;
  font-style: normal;
}

.dotgothic16 {
  font-family: "DotGothic16", sans-serif;
  font-style: normal;
}
